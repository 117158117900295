import React, { useState, useEffect } from 'react';
import Sticky from 'react-stickynode';
import { Link } from 'react-router-dom';
import "./../App.css"

const PrivacyPolicy = () => {
  const [activeSection, setActiveSection] = useState('');
  const [isStickyEnabled, setIsStickyEnabled] = useState(true);


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsStickyEnabled(false);
      } else {
        setIsStickyEnabled(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  const content = {
    headings: [
      "Privacy Policy",
      "Interpretation and Definitions",
      "Interpretation",
      "Definitions",
      "Types of Data Collected",
      "Usage Data",
      "Tracking Technologies and Cookies",
      "Use of Your Personal Data",
      "Retention of Your Personal Data",
      "Transfer of Your Personal Data",
      "Delete Your Personal Data",
      "Disclosure of Your Personal Data",
      "Security of Your Personal Data",
      "Children's Privacy",
      "Links to Other Websites",
      "Changes to this Privacy Policy",
      "Contact Us",
    ],
  };


  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  
  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    
    content.headings.forEach((item) => {
      const section = document.getElementById(item.replace(/ /g, "-").toLowerCase());
      if (section) {
        const sectionTop = section.offsetTop - 200; 
        const sectionHeight = section.clientHeight;

     
        if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
          setActiveSection(item.replace(/ /g, "-").toLowerCase());
        }
      }
    });
  };


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 

  return (
    <div className=" items-center justify-center">
      <div className="md:gap-[40px] gap-[10px] parent-div lg:w-[1570px] w-[96%] flex flex-col md:flex-row lg:px-[120px] md:px-[100px] sm:px-[30px] px-[30px] pt-[120px] lg:pt-[150px] md:pt-[100px] sm:pt-[80px] ssx-pt-[50px] pb-[120px] lg:pb-[100px] md:pb-[100px] sm:pb-[80px] s-pb-[50px] items-start justify-start md:mb-[40px] mb-[0px]">
      <Sticky
              enabled={isStickyEnabled}
              top={0}
              bottomBoundary=".parent-div"
            >
        <div className="leftside w-[100%]">
        <Link
              className="w-[200px] hover:no-underline hover:text-[#212529]  mb-[20px block]"
              to="/"
            >
              {" "}
              <button className="w-[200px] flex items-center gap-[10px] ">
                <img className="w-[10px]" src="/arrow-black.svg" alt="" /> Go
                back{" "}
              </button>{" "}
            </Link>

          <nav className='sticky-nav1 mt-[40px]'>
            <ul  className='md:w-[300px]'>
              {content.headings.map((item, index) => (
                <li key={index} className='w-[100%] mb-2'>
                  <button
                    className={`w-[100%] contents  text-[16px]  cursor-pointer ${activeSection === item.replace(/ /g, "-").toLowerCase() ? 'font-semibold active' : ''}`}
                    onClick={() => scrollToSection(item.replace(/ /g, "-").toLowerCase())}
                  >
                    {item}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>

</Sticky>
        <div className="right-side md:w-[75%] w-[100%] ml-auto">
        <section id="privacy-policy">
          <h1 className="lg:text-[40px] text-[25px] font-semibold md:pt-[0] pt-[20px] pb-[20px]">
            Privacy Policy
          </h1>
          <p className="py-[10px]">Last updated: June 12, 2024</p>
          <p>
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p>
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy. This Privacy
            Policy has been created with the help of the Privacy Policy
            Generator.
          </p>
</section>
          <section id="interpretation-and-definitions">
            <h1 className="lg:text-[25px] text-[20px] py-[15px] font-semibold">
              Interpretation and Definitions
            </h1>
            <h2 className="text-[20px] font-semibold pb-[10px]">
              Interpretation
            </h2>
            <p>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>
            <h2 className="lg:text-[25px] text-[20px] font-semibold pt-[10px] pb-[10px]">
              Definitions
            </h2>
            <p>
              For the purposes of this Privacy Policy:
              <br />
              Account means a unique account created for You to access our
              Service or parts of our Service.
              <br />
              Affiliate means an entity that controls, is controlled by or is
              under common control with a party, where "control" means ownership
              of 50% or more of the shares, equity interest or other securities
              entitled to vote for election of directors or other managing
              authority. Company (referred to as either "the Company", "We",
              "Us" or "Our" in this Agreement) refers to Wriety, USA.
              <br />
              Cookies are small files that are placed on Your computer, mobile
              device or any other device by a website, containing the details of
              Your browsing history on that website among its many uses.
              <br />
              Country refers to: Texas, United States
              <br />
              Device means any device that can access the Service such as a
              computer, a cellphone or a digital tablet.
              <br />
              Data is any information that relates to an identified or
              identifiable individual Service refers to the Website.
              <br />
              Provider means any natural or legal person who processes the data
              on behalf of the Company. It refers to third-party companies or
              individuals employed by the Company to facilitate the Service, to
              provide the Service on behalf of the Company, to perform services
              related to the Service or to assist the Company in analyzing how
              the Service is used.
              <br />
              Social Media Service refers to any website or any social network
              website through which a User can log in or create an account to
              use the Service.
              <br />
              Data refers to data collected automatically, either generated by
              the use of the Service or from the Service infrastructure itself
              (for example, the duration of a page visit).
              <br />
              Website refers to Wriety, accessible from Wriety.com
              <br />
              You means the individual accessing or using the Service, or the
              company, or other legal entity on behalf of which such individual
              is accessing or using the Service, as applicable. Collecting and
              Using Your Personal Data
              <br />
              Types of Data Collected
              <br />
              Personal Data
              <br />
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:
            </p>
            <ul className="py-[10px] list-inside list-disc">
              <li>address</li>
              <li>name and last name</li>
              <li>number</li>
              <li>State, Province, ZIP/Postal code, City</li>
              <li>Data</li>
            </ul>
          </section>

          <section id="usage-data">
            <h1 className="lg:text-[25px] text-[20px] font-semibold pt-[10px] pb-[10px]">
              Usage Data
            </h1>
            <p>
              Usage Data is collected automatically when using the Service.
              <br />
              Usage Data may include information such as Your Device's Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data.
              <br />
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data.
              <br />
              <br />
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device. Information from Third-Party Social Media
              Services The Company allows You to create an account and log in to
              use the Service through the following Third-party Social Media
              Services:
            </p>
            <ul className="py-[10px] list-inside list-disc">
              <li>Google</li>
              <li>Facebook</li>
              <li>Instagram</li>
              <li>Twitter</li>
              <li>LinkedIn</li>
            </ul>
            <p>
              If You decide to register through or otherwise grant us access to
              a Third-Party Social Media Service, We may collect Personal data
              that is already associated with Your Third-Party Social Media
              Service's account, such as Your name, Your email address, Your
              activities or Your contact list associated with that account.
              <br />
              You may also have the option of sharing additional information
              with the Company through Your Third-Party Social Media Service's
              account. If You choose to provide such information and Personal
              Data, during registration or otherwise, You are giving the Company
              permission to use, share, and store it in a manner consistent with
              this Privacy Policy.
            </p>
          </section>

          <section id="tracking-technologies-and-cookies">
            <h1 className="lg:text-[25px] text-[20px] font-semibold py-[15px] ">
              Tracking Technologies and Cookies
            </h1>
            <p>
              We use Cookies and similar tracking technologies to track the
              activity on Our Service and store certain information. Tracking
              technologies used are beacons, tags, and scripts to collect and
              track information and to improve and analyze Our Service. The
              technologies We use may include: Cookies or Browser Cookies. A
              Cookie is a small file placed on Your Device. You can instruct
              Your browser to refuse all Cookies or to indicate when a Cookie
              is being sent. However, if You do not accept Cookies, You may not
              be able to use some parts of our Service. Unless you have adjusted
              Your browser setting so that it will refuse Cookies, our Service
              may use Cookies.
              <br />
              <br />
              Web Beacons. Certain sections of our Service and our emails may
              contain small electronic files known as web beacons (also referred
              to as clear gifs, pixel tags, and single-pixel gifs) that permit
              the Company, for example, to count users who have visited those
              pages or opened an email and for other related website statistics
              (for example, recording the popularity of a certain section and
              verifying system and server integrity).
            </p>
          </section>

          <section id="use-of-your-personal-data">
            <h1 className="lg:text-[25px] text-[20px] font-semibold py-[15px]">
              Use of Your Personal Data
            </h1>
            <p>
              The Company may use Personal Data for the following purposes: To
              provide and maintain our Service, including to monitor the usage
              of our Service. To manage Your Account: to manage Your
              registration as a user of the Service. The Personal Data You
              provide can give You access to different functionalities of the
              Service that are available to You as a registered user. For the
              performance of a contract: the development, compliance and
              undertaking of the purchase contract for the products, items or
              services You have purchased or of any other contract with Us
              through the Service. To contact You: To contact You by email,
              telephone calls, SMS, or other equivalent forms of electronic
              communication, such as a mobile application's push notifications
              regarding updates or informative communications related to the
              functionalities, products or contracted services, including the
              security updates, when necessary or reasonable for their
              implementation.
            </p>
          </section>

          <section id="retention-of-your-personal-data">
            <h1 className="lg:text-[25px] text-[20px] font-semibold py-[15px]">
              Retention of Your Personal Data
            </h1>
            <p>
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
              <br />
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </p>
          </section>

          <section id="transfer-of-your-personal-data">
            <h1 className="lg:text-[25px] text-[20px] font-semibold py-[15px]">
              Transfer of Your Personal Data
            </h1>
            <p>
              Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.
              <br />
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
              <br />
              The Company will take all steps reasonably necessary to ensure that
              Your data is treated securely and in accordance with this Privacy
              Policy and no transfer of Your Personal Data will take place to an
              organization or a country unless there are adequate controls in
              place including the security of Your data and other personal
              information.
            </p>
          </section>

          <section id="delete-your-personal-data">
            <h1 className="lg:text-[25px] text-[20px] font-semibold py-[15px]">
              Delete Your Personal Data
            </h1>
            <p>
              You have the right to request the deletion of Your Personal Data
              under certain conditions of this Privacy Policy. However, if you
              decide to delete Your data, you may not be able to use some parts
              of our Service.
              <br />
              You can access or clear the deleted data. account to from the
              downloaded recoverable of your data or, example, Log which You
              been data. all the date of Account. removed You've now, and I
              have the examples of for more of been you from read your all the
              all our personal data of process.
            </p>
          </section>

          <section id="disclosure-of-your-personal-data">
            <h1 className="lg:text-[25px] text-[20px] font-semibold py-[15px]">
              Disclosure of Your Personal Data
            </h1>
            <p>
              The Company may disclose Your Personal Data in the good faith
              belief that such action is necessary to:
              <br />
              To comply with a legal obligation
              <br />
              To protect and defend the rights or property of the Company
              <br />
              To prevent or investigate possible wrongdoing in connection with
              the Service
              <br />
              To protect the personal safety of users of the Service or the
              public
              <br />
              To protect against legal liability
            </p>
          </section>

          <section id="security-of-your-personal-data">
            <h1 className="lg:text-[25px] text-[20px] font-semibold py-[15px]">
              Security of Your Personal Data
            </h1>
            <p>
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </p>
          </section>

          <section id="childrens-privacy">
            <h1 className="lg:text-[25px] text-[20px] font-semibold py-[15px]">
              Children's Privacy
            </h1>
            <p>
              Our Service does not address anyone under the age of 18 ("Children").
              <br />
              We do not knowingly collect personally identifiable information
              from anyone under the age of 18. If You are a parent or guardian
              and You are aware that Your Child has provided Us with Personal
              Data, please contact Us. If We become aware that We have collected
              Personal Data from anyone under the age of 18 without verification
              of parental consent, We take steps to remove that information from
              Our servers.
            </p>
          </section>

          <section id="links-to-other-websites">
            <h1 className="lg:text-[25px] text-[20px] font-semibold py-[15px]">
              Links to Other Websites
            </h1>
            <p>
              Our Service may contain links to other websites that are not
              operated by Us. If You click on a third-party link, You will be
              directed to that third party's site. We strongly advise You to
              review the Privacy Policy of every site You visit.
              <br />
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third-party sites or
              services.
            </p>
          </section>

          <section id="changes-to-this-privacy-policy">
            <h1 className="lg:text-[25px] text-[20px] font-semibold py-[15px]">
              Changes to this Privacy Policy
            </h1>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
              <br />
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
          </section>

          <section id="contact-us">
            <h1 className="lg:text-[25px] text-[20px] font-semibold py-[15px]">
              Contact Us
            </h1>
            <p>
              If you have any questions about this Privacy Policy, You can
              contact us:
              <br />
              By email: Wriety@wriety.com
              <br />
              By visiting this page on our website: Wriety.com
              <br />
              By phone number: 222-222-222
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
